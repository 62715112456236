import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  ChevronDownIcon, // Cambiato da ChevronRightIcon
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FaLinkedin } from "react-icons/fa";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const menuItems = [
    { name: "Competenze", href: "#skills" },
    { name: "Esperienze", href: "#experiences" },
    { name: "Progetti", href: "#projects" },
  ];

  return (
    <motion.header
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="sticky top-0 z-50 bg-white/80 backdrop-blur-sm border-b border-gray-100 shadow-sm"
    >
      <nav className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <motion.a
            whileHover={{ scale: 1.05 }}
            className="text-lg font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent flex items-center"
            href="https://www.linkedin.com/in/leonardo-cotta/"
          >
            Leonardo Cotta
            <a
              href="https://www.linkedin.com/in/leonardo-cotta/"
              className="ml-2"
            >
              <FaLinkedin className="w-6 h-6 text-green-500 hover:text-blue-600 transition-colors" />
            </a>
          </motion.a>

          <ul className="hidden md:flex space-x-8">
            {menuItems.map((item) => (
              <motion.li key={item.name} whileHover={{ scale: 1.05 }}>
                <a
                  className="text-gray-600 hover:text-black hover:bg-clip-text hover:bg-gradient-to-r from-green-500 to-blue-600 transition-all flex items-center"
                  href={item.href}
                >
                  {item.name}
                  <ChevronDownIcon className="w-4 h-4 ml-1 text-green-500" />
                </a>
              </motion.li>
            ))}
          </ul>

          {/* Hamburger Icon (Mobile) */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 text-gray-600 hover:text-black"
          >
            {isMobileMenuOpen ? (
              <XMarkIcon className="w-6 h-6" />
            ) : (
              <Bars3Icon className="w-6 h-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="md:hidden mt-4 px-4"
          >
            <ul className="space-y-4">
              {menuItems.map((item) => (
                <motion.li key={item.name} whileHover={{ scale: 1.05 }}>
                  <a
                    className="text-gray-600 hover:text-black transition-colors flex items-center"
                    href={item.href}
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item.name}
                    <ChevronDownIcon className="w-4 h-4 ml-1 text-green-500" />
                  </a>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
      </nav>
    </motion.header>
  );
};

export default Header;
