import React, { useState } from "react";
import projects from "../progetti.json";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

const Projects = () => {
  const [expandedProject, setExpandedProject] = useState(null);
  const [visibleProjects, setVisibleProjects] = useState(4);

  const toggleExpand = (index) => {
    setExpandedProject(expandedProject === index ? null : index);
  };

  const showMoreProjects = () => {
    setVisibleProjects((prev) => Math.min(prev + 4, projects.length));
  };

  // Animazione titolo fluttuante
  const floatingVariants = {
    float: {
      y: [-5, 5, -5],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.section
      id="projects"
      className="py-20 bg-gradient-to-b from-gray-900/5 to-gray-900/10"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          variants={floatingVariants}
          animate="float"
          className="mb-16 text-center"
        >
          <h2 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
            Progetti
          </h2>
          <p className="mt-4 text-gray-500">I miei progetti professionali</p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.slice(0, visibleProjects).map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-blue-600/20 rounded-2xl transform group-hover:scale-105 transition-transform duration-300" />

              <div className="relative bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300">
                <div className="flex flex-col md:flex-row items-start justify-between">
                  <div className="space-y-4 flex-1">
                    <span className="text-sm text-green-500">
                      {project.date}
                    </span>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {project.title}
                    </h3>
                    <p className="text-gray-600">
                      {project.description}
                      <AnimatePresence>
                        {expandedProject === index && (
                          <motion.span
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className="block mt-4"
                          >
                            {project.moreDescription}
                          </motion.span>
                        )}
                      </AnimatePresence>
                    </p>
                  </div>
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-32 h-32 object-cover rounded-lg mt-6 md:mt-0 md:ml-6"
                  />
                </div>

                <button
                  onClick={() => toggleExpand(index)}
                  className="flex items-center text-green-500 font-medium mt-6 hover:text-blue-600 transition-colors"
                >
                  {expandedProject === index ? "Mostra meno" : "Scopri di più"}
                  {expandedProject === index ? (
                    <ChevronUpIcon className="w-6 h-6 ml-2 transition-transform duration-300" />
                  ) : (
                    <ChevronDownIcon className="w-6 h-6 ml-2 transition-transform duration-300" />
                  )}
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {visibleProjects < projects.length && (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="flex justify-center mt-12"
          >
            <button
              onClick={showMoreProjects}
              className="inline-flex items-center text-white font-medium group px-6 py-3 bg-gradient-to-r from-green-500 to-blue-600 hover:from-green-600 hover:to-blue-700 rounded-full shadow-xl hover:shadow-2xl transition-shadow"
            >
              Mostra altro
              <ChevronDownIcon className="w-6 h-6 ml-2 transition-transform group-hover:translate-y-1" />
            </button>
          </motion.div>
        )}
      </div>
    </motion.section>
  );
};

export default Projects;
