import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

const Modal = ({ show, onClose, children }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  return (
    show && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed inset-0 bg-gray-900/70 z-50 flex items-center justify-center p-4"
      >
        <motion.div
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          className="bg-white rounded-2xl w-full max-w-2xl mx-4 flex flex-col shadow-lg"
          style={{ maxHeight: "90vh" }}
        >
          {/* Header fisso */}
          <div className="sticky top-0 bg-white p-4 flex justify-end border-b border-gray-100 z-10">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-green-500"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>

          {/* Contenuto scrollabile */}
          <div className="overflow-y-auto flex-1 p-2">
            <div className="prose max-w-4xl mx-auto">{children}</div>
          </div>
        </motion.div>
      </motion.div>
    )
  );
};

export default Modal;
