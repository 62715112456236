import React from "react";
import { motion } from "framer-motion";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { FaEnvelope } from "react-icons/fa";

const Contact = () => {
  return (
    <motion.footer
      className="py-20 bg-gradient-to-b from-gray-900/5 to-gray-900/10 text-gray-900 px-4"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12">
          <motion.div initial={{ x: -20 }} animate={{ x: 0 }}>
            <h2 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent mb-6">
              Contatti
            </h2>
            <div className="flex flex-col space-y-4 overflow-hidden">
              <a
                href="mailto:leonardo.cotta2002@gmail.com"
                className="flex items-center group"
              >
                <FaEnvelope className="w-4 h-4 mr-2 text-green-500" />
                <span className="border-b border-transparent hover:border-green-500">
                  leonardo.cotta2002@gmail.com
                </span>
              </a>
              <div className="flex space-x-6 mt-4">
                {[
                  {
                    platform: "LinkedIn",
                    url: "https://www.linkedin.com/in/leonardo-cotta/",
                  },
                  { platform: "GitHub", url: "https://github.com/Meneyvaro11" },
                  {
                    platform: "Instagram",
                    url: "https://www.instagram.com/leonardocotta_/",
                  },
                ].map(({ platform, url }) => (
                  <motion.a
                    key={platform}
                    whileHover={{ y: -2 }}
                    className="flex items-center group"
                    href={url}
                  >
                    {platform}
                    <ChevronRightIcon className="w-4 h-4 ml-1 text-green-500 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </motion.a>
                ))}
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ x: 20 }}
            animate={{ x: 0 }}
            className="border-l border-gray-700 pl-12"
          >
            <h3 className="text-xl font-semibold mb-6">Disponibilità</h3>
            <p className="text-gray-600 leading-relaxed">
              Aperto a nuove opportunità e collaborazioni.
              <br />
              Rispondo entro 24 ore durante i giorni lavorativi.
            </p>
          </motion.div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Contact;
