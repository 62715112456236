// App.js
import { motion } from "framer-motion";
import Header from "./components/Header";
import Experiences from "./components/Experiences";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import About from "./components/About";

const App = () => {
  return (
    <div className="App bg-gray-50 overflow-x-hidden">
      <motion.div
        className="max-w-[100vw]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Header />
        <About />
        <Skills />
        <Experiences />
        <Projects />
        <Contact />
      </motion.div>
    </div>
  );
};

export default App;
