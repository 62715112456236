import React, { useState } from "react";
import ProfilePhoto from "../assets/emoji.gif";
import { motion } from "framer-motion";
import {
  ChevronDownIcon,
  ChevronUpIcon, // Aggiungi questa importazione
  ChevronRightIcon,
} from "@heroicons/react/24/outline"; // Rimuovere l'importazione errata di LinkedinIcon
import Modal from "./Modal";

const About = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showDefinition, setShowDefinition] = useState(false);

  const services = [
    {
      title: "Web & App Developer 2.0 & 3.0",
      content:
        "Sviluppo siti web, web app, mobile app, e-commerce, videogiochi e piattaforme avanzate 3.0, curando design, performance e scalabilità. Ottimizzo la SEO per migliorare il posizionamento.",
    },
    {
      title: "Branding, Design & UI/UX",
      content:
        "Sviluppo Brand Identity, materiali visivi per comunicazione e marketing. Ottimizzazione UI e UX per interfacce intuitive e esperienze utente fluide ed efficienti.",
    },
    {
      title: "Marketing & Management",
      content:
        "Sviluppo strategie di crescita, gestisco campagne pubblicitarie, di brand & artist management, curando la strategia, comunicazione e posizionamento per massimizzare visibilità e risultati",
    },
    {
      title: "Event & Project Management",
      content:
        "Organizzo e gestisco eventi, dalla pianificazione alla realizzazione. Coordino progetti complessi, ottimizzando risorse, tempistiche e strategie per raggiungere gli obiettivi con efficienza.",
    },
    {
      title: "E-commerce & Vendite Online",
      content:
        "Sviluppo e gestisione e-commerce, marketing, advertising, logistica e customer experience. Ottimizzo le vendite online attraverso campagne mirate, gestione operativa massimizzando conversioni e fidelizzazione.",
    },
    {
      title: "Videomaking & Art Direction",
      content:
        "Creazione contenuti visivi ad alto impatto, video promozionali, contenuti per social media. Sviluppo strategie di content marketing per aumentare engagement e valorizzare brand e progetti",
    },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    setIsClicked(!isClicked);
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: "-100px" }}
      className="py-20 px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto bg-gradient-to-b from-gray-900/5 to-gray-900/10"
    >
      {/* Prima Riga: Immagine + Titolo */}
      <div className="flex flex-col items-center gap-6 mb-12">
        <motion.img
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring" }}
          className="w-24 h-24 rounded-full bg-gray-50 border-4 border-white shadow-lg"
          src={ProfilePhoto}
          alt="Profile"
        />
        <div className="text-center">
          <div className="flex items-center justify-center gap-2">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
              Cross-Skills Specialist
            </h1>
          </div>
          <button
            onClick={() => setShowDefinition(true)}
            className="text-sm text-green-500 hover:text-blue-600 underline underline-offset-4 mt-1"
          >
            Cosa significa esattamente?
          </button>
        </div>
      </div>

      {/* Testo */}
      <div className="text-lg text-gray-600 leading-relaxed space-y-6 mb-12 px-4">
        <h1>👋 Ciao!</h1>
        <p>
          Mi chiamo <strong>Leonardo</strong> e sono un{" "}
          <strong>Cross-Skills Specialist</strong>, un professionista poliedrico
          con competenze in <strong>design</strong>,{" "}
          <strong>sviluppo web &amp; app</strong>, <strong>videomaking</strong>,{" "}
          <strong>marketing</strong> e <strong>gestione progetti</strong>. È un
          piacere averti sul mio profilo! Sei qui per scoprire di più su di me?
          Allora iniziamo!
        </p>

        {isClicked && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-6 mt-6"
          >
            <p>
              Fin da bambino, sono stato affascinato dalla{" "}
              <strong>tecnologia</strong>, dal <strong>design</strong>, dalla{" "}
              <strong>programmazione</strong> e dalla{" "}
              <strong>produzione multimediale</strong>. A{" "}
              <strong>12 anni</strong>, gestivo e sviluppavo server di gioco su{" "}
              <strong>Linux</strong>, organizzavo team di moderazione e creavo
              siti web, grafiche e contenuti video. Questa attitudine a
              collegare e applicare competenze diverse mi ha accompagnato in
              tutta la mia carriera.
            </p>

            <p>
              Dopo la scuola media, ho scelto un percorso che rispecchiasse la
              mia versatilità, iscrivendomi all'
              <strong>Istituto Superiore Starting Work</strong> a Como. Qui ho
              maturato oltre <strong>5.000 ore</strong> di stage e
              apprendistato, sia in Italia che all’estero, sviluppando una
              solida esperienza pratica e multidisciplinare. Successivamente, ho
              proseguito gli studi conseguendo un diploma accademico seguendo il
              corso <strong>"Web &amp; App Development"</strong> presso l'
              <strong>accedemia Mohole</strong> a Milano.
            </p>

            <p>
              Oltre agli studi formali, ho sempre approfondito autonomamente le
              discipline che mi appassionano, seguendo corsi su piattaforme come{" "}
              <strong>Udemy</strong> e <strong>YouTube</strong>, partecipando a
              forum di settore e sperimentando con progetti reali.
            </p>
          </motion.div>
        )}
      </div>

      {/* Pulsante Mostra Più/Meno */}
      <motion.a
        whileHover={{ scale: 1.05 }}
        className="inline-flex items-center text-green-500 font-medium mb-12 group px-4"
        onClick={handleClick}
      >
        {isClicked ? "Mostra meno" : "La mia storia completa"}
        {isClicked ? (
          <ChevronUpIcon className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1" />
        ) : (
          <ChevronDownIcon className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1" />
        )}
      </motion.a>

      {/* Card */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <motion.div
            key={index}
            initial={{ y: 20 }}
            whileInView={{ y: 0 }}
            className="relative group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-blue-600/20 rounded-2xl transform group-hover:scale-105 transition-transform duration-300" />

            <div className="relative p-6 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-xl font-semibold mb-3 text-gray-900">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.content}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Modal Definizione */}
      <Modal show={showDefinition} onClose={() => setShowDefinition(false)}>
        <div className="prose max-w-4xl p-6 mx-auto">
          <h2 className="text-3xl font-bold ">Cross-Skills Specialist</h2>
          <span
            onClick={() => setShowDefinition(true)}
            className="text-sm text-gray-500 "
          >
            CS Specialist or CSS
          </span>

          <p className="text-lg mb-4 mt-6">
            <strong className="underline">Definizione:</strong> Professionista
            multidisciplinare, dinamico e flessibile, con competenze distribuite
            su più settori e la capacità di applicarle direttamente nei
            progetti.
          </p>

          <p className="text-lg mb-4">
            Nel mondo di oggi, in cui le tecnologie e le competenze richieste
            cambiano rapidamente, avere una figura capace di muoversi tra più
            discipline è un enorme vantaggio. Un Cross-Skills Specialist sa
            quando e come integrare le competenze, ottimizzare i flussi di
            lavoro ed evitare inefficienze, sia lavorando in prima persona che
            affiancando specialisti in diversi settori.
          </p>

          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-3">
                Perché è essenziale?
              </h3>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Integra competenze diverse <strong>evitando sprechi</strong>
                </li>
                <li>
                  Ottimizza i <strong>flussi di lavoro</strong>
                </li>
                <li>
                  Funge da <strong>ponte tra reparti</strong>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-3">
                Ambiti di Competenza
              </h3>
              <div className="grid md:grid-cols-2 gap-4">
                {[
                  "Branding, Design & UI/UX",
                  "Sviluppo Web/App 2.0 & 3.0",
                  "Marketing & Comunicazione",
                  "Event & Project Management",
                  "Videomaking & Content Creation",
                  "E-commerce & Vendite Online",
                ].map((item, i) => (
                  <div key={i} className="flex items-center">
                    <ChevronRightIcon className="w-4 h-4 mr-2 text-green-500" />
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </motion.section>
  );
};

export default About;
