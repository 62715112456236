import React, { useState } from "react";
import experiences from "../esperienze.json";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";

const Experiences = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [visibleCount, setVisibleCount] = useState(3);

  const handleToggle = (index) => {
    setExpandedIndex((prev) => (prev === index ? null : index));
  };

  const handleShowMore = () => {
    setVisibleCount((prev) => Math.min(prev + 3, experiences.length));
  };

  // Animazione titolo fluttuante
  const floatingVariants = {
    float: {
      y: [-5, 5, -5],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.section
      id="experiences"
      className="py-20 bg-gradient-to-b from-gray-900/5 to-gray-900/10"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          variants={floatingVariants}
          animate="float"
          className="mb-16 text-center"
        >
          <h2 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
            Esperienze
          </h2>
          <p className="mt-4 text-gray-500">La mia evoluzione professionale</p>
        </motion.div>

        <div className="grid gap-8">
          {experiences.slice(0, visibleCount).map((exp, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ delay: index * 0.2, type: "spring" }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-blue-600/20 rounded-2xl transform group-hover:scale-105 transition-transform duration-300" />

              <div className="relative bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
                <div className="flex flex-col md:flex-row justify-between items-start gap-6">
                  <div className="space-y-2">
                    <span className="text-sm font-semibold text-green-500">
                      {exp.date}
                    </span>
                    <h3 className="text-2xl font-bold text-gray-900">
                      {exp.title}
                    </h3>
                    {exp.company && (
                      <p className="text-sm font-medium text-blue-600">
                        {exp.company}
                      </p>
                    )}
                  </div>

                  <button
                    onClick={() => handleToggle(index)}
                    className="flex items-center gap-2 text-green-500 hover:text-blue-600 transition-colors"
                  >
                    <span className="hidden md:inline">
                      {expandedIndex === index ? "Riduci" : "Espandi"}
                    </span>
                    {expandedIndex === index ? (
                      <ChevronUpIcon className="w-6 h-6 animate-bounce" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 animate-pulse" />
                    )}
                  </button>
                </div>

                <div className="mt-6 space-y-4">
                  <p className="text-gray-600 leading-relaxed">
                    {exp.description}
                  </p>

                  <AnimatePresence>
                    {expandedIndex === index && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="overflow-hidden"
                      >
                        <div className="pt-6 border-t border-gray-100/50">
                          <pre className="whitespace-pre-wrap font-sans text-gray-700">
                            {exp.moreDescription}
                          </pre>

                          {exp.links && exp.links.length > 0 && (
                            <div className="mt-8 flex flex-wrap gap-3">
                              {exp.links.map((link, linkIndex) => (
                                <motion.a
                                  key={linkIndex}
                                  href={link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  whileHover={{ scale: 1.05 }}
                                  className="flex items-center px-4 py-2 bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
                                >
                                  <LinkIcon className="w-5 h-5 mr-2" />
                                  {link.label}
                                </motion.a>
                              ))}
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {visibleCount < experiences.length && (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="flex justify-center mt-16"
          >
            <button
              onClick={handleShowMore}
              className="inline-flex items-center text-white font-medium group px-6 py-3 bg-gradient-to-r from-green-500 to-blue-600 hover:from-green-600 hover:to-blue-700 rounded-full shadow-xl hover:shadow-2xl transition-shadow"
            >
              <span className="font-semibold">Mostra altro</span>
              <motion.div
                animate={{ y: [-3, 3, -3] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                <ChevronDownIcon className="w-6 h-6" />
              </motion.div>
            </button>
          </motion.div>
        )}
      </div>
    </motion.section>
  );
};

export default Experiences;
