import { motion } from "framer-motion";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Skills = () => {
  const skillsCategories = [
    {
      title: "Soft Skills",
      items:
        "Determinazione, ambizione, attitudine alla crescita e al miglioramento costante, problem solving, pensiero critico e analisi, spirito di iniziativa, flessibilità, empatia, adattamento, teamwork",
    },
    {
      title: "Development",
      items:
        "Html, Css, Sass, Tailwind, Bootstrap, JavaScript, Typescript, PHP, Twig, mySQL, Firebase, Supabase, Python, React, Next, React Native, Flutter, NodeJs, Cordova",
    },
    {
      title: "Design",
      items: "Illustrator, Photoshop, Figma, Adobe XD, InDesign",
    },
    {
      title: "Video Making",
      items: "Premiere Pro, After Effects, Da Vinci",
    },
  ];

  // Animazione titolo fluttuante
  const floatingVariants = {
    float: {
      y: [-5, 5, -5],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.section
      id="skills"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: "-100px" }}
      className="py-20 bg-gradient-to-b from-gray-900/5 to-gray-900/10"
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          variants={floatingVariants}
          animate="float"
          className="mb-16 text-center"
        >
          <h2 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
            Competenze
          </h2>
          <p className="mt-4 text-gray-500">Competenze & Strumenti</p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {skillsCategories.map((category, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-blue-600/20 rounded-2xl transform group-hover:scale-105 transition-transform duration-300" />

              <div className="relative bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300 mx-2 md:mx-0">
                <div className="flex items-start mb-4">
                  <ChevronRightIcon className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <h3 className="text-xl font-semibold text-gray-900">
                    {category.title}
                  </h3>
                </div>
                <motion.p
                  className="text-gray-600 leading-relaxed"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  {category.items}
                </motion.p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Skills;
